import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import { useSpring } from "react-spring"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const HeroSection = styled.section`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: min-content;
  margin: 0 auto;
  margin-top: 7em;
  max-width: 1400px;

  a,
  a:visited,
  a:link {
    color: var(--primary-orange);
  }

  a:hover,
  a:focus {
    color: var(--orange-hover);
  }

  @media only screen and (max-width: 860px) {
    display: flex;
    flex-direction: column;
  }
`

const HeroImage = styled.div`
  grid-column: 1 / 7;
  grid-row: 1 / 4;
  width: 100%;
  height: 100%;
`

const HeroText = styled.div`
  grid-column: 6 / -1;
  grid-row: 2 / 3;
  z-index: 500;
  color: var(--primary-orange);
  background-color: var(--transparent-white);
  padding: 1em;

  p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  @media only screen and (max-width: 860px) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
`

const MainSection = styled.section`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: min-content;
  margin: 0 auto;
  max-width: 1400px;

  &:last-of-type {
    padding-bottom: 6em;
  }

  h3 {
    font-size: 1rem;
    grid-column: 3 / 8;
    font-weight: 500;
    font-style: italic;
    margin-top: 2em;
  }

  p {
    grid-column: 3 / 8;
    margin-top: 2.5em;
  }

  p:empty {
    height: 0;
    visibility: hidden;
    margin-top: 0;
  }

  figure {
    margin-top: 3em;
    grid-column: 2 / 7;

    p {
      margin-top: 0.3em;
      font-style: italic;
      font-size: 0.8em;
    }
  }

  a,
  a:visited,
  a:link {
    color: var(--primary-orange);
  }

  a:hover,
  a:focus {
    color: var(--orange-hover);
  }

  .container {
    grid-column: 2 / 7;
    margin-top: 3em;
  }

  @media only screen and (max-width: 860px) {
    display: flex;
    flex-direction: column;
  }
`

export default function CaseStudyTemplate({ data }) {
  const post = data.markdownRemark
  const { title, desc, link } = post.frontmatter
  const image = getImage(post.frontmatter.img)
  return (
    <Layout>
      <HeroSection
        style={useSpring({
          to: { opacity: 1 },
          from: { opacity: 0 },
          delay: 500,
        })}
      >
        <HeroImage>
          <GatsbyImage image={image} alt={title} />
        </HeroImage>
        <HeroText>
          <h2>{title}</h2>
          <p>{desc}</p>
          {link !== "none" && (
            <a href={link} alt={title} target="_blank" rel="noreferrer">
              Go to site
            </a>
          )}
        </HeroText>
      </HeroSection>
      <MainSection
        style={useSpring({
          to: { opacity: 1 },
          from: { opacity: 0 },
          delay: 700,
        })}
        dangerouslySetInnerHTML={{ __html: post.html }}
      />
      <MainSection>
        <p style={{ fontSize: "1.3em", gridColumn: "2/7" }}>
          <AniLink cover direction="right" bg="#F55742" to="/#projects">
            Back to projects
          </AniLink>
        </p>
      </MainSection>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        desc
        link
        img {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
